const PL = {
  translation: {
    aboutUs: "O nas",
    aboutProject: "Informacje o projekcie",
    trustedUs: "Zaufali nam",
    ourBrands: "Marki, w które inwestujemy",
    ourHistory: "Historia",
    voucher: "Voucher",
    contact: "Kontakt",
    content1par1:
      "Jesteśmy grupą kapitałową, która łączy podmioty gospodarcze działające na różnych płaszczyznach w sektorach IT i cyberbezpieczeństwa, finansowym, szkoleniowym, marketingowym oraz nowych mediów. Dzięki wykorzystaniu najnowszych technologii i ponad 20-letniemu doświadczeniu potrafimy łączyć sprawdzone rozwiązania z innowacją w branży informatycznej i zarządczej. Tworzymy optymalne warunki podczas realizacji projektów dzięki najnowszym rozwiązaniom, w tym wykorzystaniu systemów bezpieczeństwa oraz technologii sztucznej inteligencji. Pomagamy w pozyskaniu dofinansowania unijnego, zewnętrznego i inwestorskiego na oprogramowanie, sprzęt oraz rozwój i szkolenia. Firma aktywnie wspiera różnorodne inicjatywy biznesowe, społeczne i charytatywne, za co była wielokrotnie wyróżniana.",
    content1par2:
      "Jako lider w dziedzinie innowacji, BluHolding zrzesza wybitne marki tworząc synergiczny holding. Naszym celem jest przyspieszenie postępu technologicznego poprzez badania, analizę oraz wykorzystanie najnowocześniejszych rozwiązań do wzbogacenia oferty dla przedsiębiorstw, a także powiększenie grona klientów na skalę globalną.",
    content1par3:
      "Zachęcamy partnerów i inwestorów do dołączenia do naszej grupy akcjonariuszy. Nabywając akcje BluHolding, stają się Państwo integralną częścią naszych osiągnięć i przyszłych sukcesów. Inwestując w nasze zróżnicowane marki mają Państwo możliwość uczestniczenia w przełomowych projektach technologicznych.",
    content1par4:
      "Zapraszamy Państwa do poznania struktury BluHolding oraz obszarów działania zrzeszonych marek.",
    content1par5: "",
    boss: "Krzysztof Błaszkiewicz",
    bossTitle: "Prezes",
    content2par1:
      "BluHolding to grupa kapitałowa działająca w branży nowych technologii, finansowej oraz mediów.",
    content2par2: "DZIAŁAMY LOKALNIE DOCIERAMY GLOBALNIE.",
    whatIs: "Czym jest",
    inNumbers: "w liczbach",
    becomeAShareHolder: "ZOSTAŃ INWESTOREM",
    emissionProgress: "postęp emisji",
    d01012023: "10.02.2023",
    startDate: "data rozpoczęcia",
    project: "Projekt",
    inProgress: "w trakcie",
    investments: "Liczba inwestorów",
    rate: "Stopa zwrotu (ROE za 2023 r.)",
    successTreshold: "próg powodzenia",
    projectDesc: "Opis projektu",
    investPackage: "Pakiety inwestorskie",
    discuss: "Najczęściej zadawane pytania (FAQ)",
    documents: "Dokumenty",
    docPrivacyPolicy: "Polityka Prywatności",
    docTrademark: "Prawo ochronne na znak towarowy",
    docRZIS: "RZIS ostatnie 12 miesięcy",
    docValuation: "Wycena spółki BluHolding",
    docCashFlows: "Rachunek Przepływów",
    docInformation: "Dokument informacyjny",
    docAnnouncement: "Ogłoszenie",
    docRODO: "RODO",
    docKRS: "KRS",
    docBalance: "Bilans 2023",
    investList: "Lista inwestorów",
    risks: "Czynniki ryzyka",
    infoAbout: "Informacje o",
    startWriting: "Zacznij pisać",
    logIn: "Zaloguj się",
    sendComment: "WYŚLIJ KOMENTARZ",
    lessThanHour: "mniej niż godzinę temu",
    years: "lat temu",
    year: "rok temu",
    day: "dzień temu",
    days: "dni temu",
    hours: "godzin temu",
    nameHidden: "Dane ukryte",
    more: "WIĘCEJ",
    less: "MNIEJ",
    partners: "Partnerzy",
    ourInvestments: "Nasze inwestycje",
    invest1Title: "Inwestujemy w ludzi",
    invest1Content:
      "Nowe stanowiska pracy - wzrost zatrudnienia | Certyfikowane szkolenia i kursy dla pracowników - poszerzanie kompetencji i kwalifikacji | Specjalistyczne szkolenia techników | Staże i praktyki zawodowe - potencjał przyszłych pracowników | Strefa chill - komfort pracy to lepsze wyniki i efektywna realizacja zadań | Modernizacja stanowisk pracy - nowy sprzęt, aktualizacje oprogramowania | Rozbudowa sieci przedstawicieli i floty firmowej",
    invest2Title: "Nowe technologie",
    invest2Content:
      "Centrum badawcze - nowy ośrodek badawczo szkoleniowy | Badania nad wykorzystaniem sztucznej inteligencji AI w różnych dziedzinach | Laboratoria - dedykowane stanowiska na potrzeby eksperymentów | Rozwój transmisji online i technologii streamu | Motoryzacja - sektor samochodów elektrycznych",
    invest3Title: "Bezpieczeństwo",
    invest3Content:
      "Cyberbezpieczeństwo - szkolenia i edukacja to wzrost samoświadomości użytkowników systemów informatycznych | Audyty bezpieczeństwa - chronimy dane polskich i zagranicznych podmiotów | Ekspertyzy i analiza wyników - ciągłe samodoskonalenie i rozwój | Monitoring i powiadomienia systemowe",
    invest4Title: "Rozwój produktów i organizacji",
    invest4Content:
      "Profesjonalne studio telewizyjne BluStremTV | Modernizacja Data Center - Rozwój infrastruktury serwerowej | Hosting Magento, skalowanie zasobów - horyzontalne i verticalne | Bezpieczeństwo danych - rozwój produktów chmury | Automatyzacja i optymalizacja procesów firmowych - standardy ISO",
    invest5Title: "Inwestujemy w przyszłość",
    invest5Content:
      " Centrum szkoleniowe - nowoczesne sale szkoleniowe i konferencyjne | Strefy coworkingowe - więcej możliwości i leadów biznesowych | Atestowane wyposażenie biurowe - standardy BHP |  Profesjonalny sprzęt dla telekonferencji i nauczania online | Interaktywna platforma szkoleniowa on-line",
    value: "Wartość",
    from: "od",
    shares: "akcji",
    companyHistory: "Historia firmy",
    epulpitShort:
      "Usługa umożliwia nawiązanie zdalnego połączenia z wirtualnym serwerem.",
    epulpitLong:
      "Usługa umożliwia nawiązanie zdalnego połączenia z wirtualnym serwerem. To coś więcej niż dysk w chmurze, Dropbox czy Google drive. Obrazowo, e-Pulpit24 to Twój cały komputer z Twoimi programami, aplikacjami i danymi, dostępny z dowolnego urządzenia i miejsca na całym świecie. E-pulpit24 to usługa, która umożliwia jednoczesną pracę wielu użytkowników znajdujących się w różnych lokalizacjach na jednym wirtualnym serwerze.. Takie rozwiązanie doskonale sprawdza się w organizacjach, gdzie kilku pracowników korzysta z jednej aplikacji i bazy danych. Kwestia bezpieczeństwa Twoich danych ma dla nas najwyższy priorytet. regularnie wykonujemy kopie bezpieczeństwa, w trybie ciągłym monitorujemy stan serwerów i urządzeń w naszym Data Center. Natychmiast reagujemy na wszelkie nieprawidłowości oraz świadczymy także usługi VPN dla pulpitu zdalnego dla bardziej wymagających osób.",
    blusoftShort: "Integrator usług informatycznych.",
    blusoftLong:
      "Firma informatyczna z wieloletnim doświadczeniem i renomą na rynku. Prowadzimy audyty bezpieczeństwa w przedsiębiorstwach i wdrażamy opiekę informatyczną. Przekaż w ręce specjalistów wszelkie problemy ze sprzętem. Z powodzeniem zajmujemy się projektowaniem sieci i serwerowni. Jeśli planujesz nową inwestycję przeprowadzimy Cię przez cały proces wdrożenia. Pokażemy Ci, jakie masz alternatywy, doradzimy co wybrać, a także dostarczymy niezbędny sprzęt - niezawodne serwery oraz komputery. Oferujemy również usługę hostingu oprogramowania dedykowanego, CRM, ERP, bądź innego, wymagającego nieprzerwanej pracy i niezawodnych zabezpieczeń. Nasza infrastruktura zapewnia bezawaryjną i ciągłą pracę. Codziennie wykonujemy kopie zapasowe powierzonych nam danych. Nieustannie monitorujemy nasze systemy, by wyeliminować zagrożenia w sieci i możliwość dostępu do wrażliwych danych osobom niepowołanym.",
    bluserwerShort: "Hosting, domeny, data center.",
    bluserwerLong:
      "Oferujemy niezawodny i stabilny hosting, wysoki poziom bezpieczeństwa danych oraz profesjonalną opiekę informatyczną. Hosting to usługa wynajmu przestrzeni dyskowej dzierżawionej z zasobów serwera. Każda strona internetowa, utrzymywana jest właśnie na serwerze, działającym nieprzerwanie i widocznym w Internecie. Zapewnia on tym samym przechowywanie i przetwarzanie wszystkich danych, z których korzysta strona. Nasza serwerownia wyposażona jest w wysokiej klasy serwery, macierze dyskowe oraz urządzenia sieciowe, których stan monitorowany jest 24 h na dobę. Wychodząc naprzeciw coraz to nowym zagrożeniom cyberbezpieczeństwa, wdrażamy i na bieżąco aktualizujemy systemy zabezpieczeń. Dzięki tym zabiegom gwarantujemy pełną dostępność oraz bezpieczeństwo danych i usług. Dysponujemy profesjonalnie wyszkoloną kadrą techników i inżynierów IT, która służy radą i pomocą w rozwiązaniu pojawiających się problemów. Jako certyfikowany partner NASK, rejestrujemy również domeny i pomagamy w ich zakupie.",
    blusignShort: "Podpis elektroniczny.",
    blusignLong:
      "Podpis elektroniczny jest narzędziem, które umożliwia identyfikację tożsamości podmiotów przesyłających dokumenty drogą elektroniczną. Składanie elektronicznych formularzy do urzędów zyskuje na popularności wśród przedsiębiorców, ponieważ daje możliwość zaoszczędzenia czasu. Coraz więcej branż otwiera się na taką formę współpracy. Dzięki podpisowi elektronicznemu możliwe jest stawanie do przetargów administracji samorządowej i rządowej JEDZ Umożliwia on także internetową wymianę dokumentów z instytucjami takimi jak ZUS, Urząd Skarbowy, Urząd Celny, KRS itp. Korzystanie z podpisu elektronicznego to znaczne ułatwienie - dzięki tej usłudze zyskujesz możliwości zdalnego zawierania umów cywilno-prawnych, czy przesyłania faktur, a to coraz częściej staje się wymogiem. Poszczególne grupy zawodowe, muszą się dostosować do standardów prowadzenia dokumentacji w formie elektronicznej, co wiąże się z koniecznością posiadania kwalifikowanego podpisu: służba zdrowia, sądownictwo, więziennictwo. Wybierz najkorzystniejszą wersję podpisu elektronicznego z naszego katalogu usług, a zobaczysz jakie to wygodne!",
    blustreamtvShort: "Produkcja telewizyjna, transmisja live, platforma PPV.",
    blustreamtvLong:
      "Realizator transmisji live i właściciel platformy PPV. Oferujemy szerokie możliwości promocji marki, czy wydarzenia na nieograniczoną skalę. Docieramy wszędzie tam, gdzie sięga Internet. Profesjonalny zespół operatorów kamer, montażystów oraz dźwiękowców zapewnia kompleksową obsługę transmisji, bazując na wieloletnim doświadczeniu i niezawodnym sprzęcie najwyższej jakości. Zapewnimy przesył transmisji audiowizualnej z konferencji biznesowych, koncertów, szkoleń, webinarów oraz zawodów sportowych z wszystkich możliwych dyscyplin. Organizujemy pełną obsługę wydarzeń, począwszy od stworzenia scenariusza, przez promocję w sieci, konferencję prasową dla mediów, aż po realizację na miejscu - obsługę studia live, konferansjerkę. Ponadto oferujemy możliwość skorzystania ze zintegrowanej platformy pay per view, dającej możliwość sprzedaży biletów on-line.",
    blustreamacadShort: "Szkolenia operatorów i dziennikarzy.",
    blustreamacadLong:
      "Jesteśmy akademią szkolącą przyszłych operatorów kamer, realizatorów transmisji, a także montażystów wideo. Wykwalifikowana i doświadczona wieloma realizacjami transmisji kadra dzieli się swoją wiedza z uczestnikami kursu. Jedyna i niepowtarzalna szansa, by ćwiczyć umiejętności w praktyce, podczas rzeczywistych realizacji, eventów, konferencji biznesowych, a także wydarzeń sportowych. Posiadamy swoje własne, bogato wyposażone studio telewizyjne. BluStreamTV Academy zapewnia możliwość pracy na profesjonalnym sprzęcie i oprogramowaniu oraz zdobycie cenionego w branży doświadczenia zawodowego.",
    adshootShort: "Dom mediowy, agencja marketingowa, agencja kreatywna.",
    adshootLong:
      "Jesteśmy agencją marketingową specjalizującą się w obsłudze mediów społecznościowych. Tworzymy kreatywne, przyciągające uwagę i wyróżniające się treści na portalach biznesowych w social mediach, takich jak Facebook, Instragram, LinkedIn i YouTube.. Planujemy kampanie reklamowe, strategie i budżety. Jesteśmy zespołem, który w nieszablonowy sposób tworzy działania PR-owe i marketingowe. Posiadamy również wyspecjalizowanych copywriterów, mogących sprostać najwyższym oczekiwaniom. Dysponując profesjonalnym sprzętem filmowym oraz wykwalifikowaną grupą specjalistów tworzymy najwyższej jakości nagrania filmowe oraz materiały multimedialne. Zaufaj nam, a zadbamy o Twój wizerunek, aby zwiększyć świadomość marki wśród obecnych i nowych klientów. Udowodnimy, że obecność w social mediach to działania wizerunkowe, które przekładają się na realną sprzedaż i wyniki finansowe. Comiesięczny raport naszej pracy da Ci pełną kontrolę nad efektami prowadzonej kampanii.",
    fundacjaShort: "Start-up, szkolenia, przestrzeń dla biznesu.",
    fundacjaLong:
      "Zadaniem organizacji jest wskazanie dróg do rozwoju start-upów, istniejących już działalności gospodarczych oraz wsparcie lokalnego biznesu, działającego na terenie województwa kujawsko-pomorskiego. Fundacja BluSpace pomaga wielu przedsiębiorcom sięgać po narzędzia ułatwiające pracę lub przyspieszające rozwój firm. Zespół pracujących dla fundacji specjalistów i fachowców z wieloletnim doświadczeniem w swoich dziedzinach pomaga rozwijać kompetencje przedsiębiorców w zakresie networkingu, wymiany doświadczeń, budowania grupy wsparcia lokalnego biznesu, a także korzystania z najnowszych technologii. Nasi eksperci potrafią kompleksowo doradzić i obsłużyć firmy, począwszy od stworzenia strategii promocji marki, przez logo czy strony www, doradztwo podatkowe, aż do pozyskania pierwszych klientów i utrzymywania z nimi trwałej relacji biznesowej.",
    bgsShort: "Turniej e-sportowy, expo, Targi Pracy, Biznes Zone.",
    bgsLong:
      "Blu Game Show to wciąż rozwijające się wydarzenie gamingowe, które otrzymało szerokie wsparcie zarówno ze strony instytucji, jak i społeczności gamingowej. Możemy pochwalić się honorowymi patronatami Ministerstwa Sportu i Turystyki, Marszałka Województwa Kujawsko-Pomorskiego oraz Prezydenta Miasta Bydgoszczy, które świadczą o znaczeniu, jakie Blu Game Show zdobyło w świecie e-sportu i kultury gamingowej. Nasze wydarzenie to nie tylko emocjonujący turniej CS:GO i FIFA, ale także wspaniałe targi z bogatą ofertą atrakcji. Na uczestników czekać będą wystawcy z branży IT, nowoczesnych technologii, fantasy i fandomu. Ponadto, nie zabraknie miejsca dla fanów anime, mangi, a także specjalnej Blu Game Show Zone, która stanowi doskonałą przestrzeń do networkingu i rozmów biznesowych. To nie tylko wydarzenie dla pasjonatów gier komputerowych, ale także doskonała okazja do nawiązania cennych kontaktów biznesowych, wymiany doświadczeń i odkrywania nowych możliwości w branży IT i nowoczesnych technologii.",
    bbcShort: "Spotkania biznesowe, rynek inwestycji.",
    bbcLong:
      "Jesteśmy grupą przedsiębiorców z Bydgoszczy, którzy wzajemnie wspierają rozwój własnych firm. Organizujemy cotygodniowe spotkania, które pozwalają nam być na bieżąco w sytuacji biznesowej na lokalnym rynku. Nieustannie analizujemy możliwości inwestycji i podnosimy swoje kompetencje. Angażujemy się w projekty, które przyczyniają się do promocji miasta oraz lokalnego biznesu, tym samym wzmacniając pozycję regionu na rynku polskim i międzynarodowym.",
    bofficeShort:
      "Wirtualne biuro to najbardziej ekonomiczna forma prowadzenia działalności w atrakcyjnej lokalizacji w Bydgoszczy.",
    bofficeLong:
      "Wirtualne biuro to najbardziej ekonomiczna forma prowadzenia działalności w atrakcyjnej lokalizacji w Bydgoszczy. Nasza oferta skierowana jest do istniejących firm lub osób zamierzających otworzyć swoją działalność. Dzięki BluOffice możesz prowadzić firmę w centrum miasta, bez konieczności wynajmowania kosztownej powierzchni biurowej i podpisywania długoterminowej umowy. Potrzebujesz rozdzielić adres zamieszkania od adresu siedziby firmy? Chcesz otworzyć nowy oddział firmy? Skorzystaj z wirtualnego adresu! Nasze biuro mieści się na bydgoskim osiedlu Bartodzieje. Siedziba przedsiębiorstwa w Centrum miasta podnosi zarówno prestiż jak i wiarygodność Twojej firmy.",
    bemShort: "Finanse i rachunkowość, księgowość, kadry.",
    bemLong:
      "Wspieramy klientów w prowadzeniu działalności gospodarczej, nie tylko od strony finansowej i rachunkowej, ale też kadrowej i księgowej. Jesteśmy doświadczonym zespołem, który wciąż podnosi swoje kompetencje w dziedzinie finansów, rachunkowości i prawa pracy. Świadczymy kompleksową obsługę dla wszystkich przedsiębiorców, których księgowość oparta jest na księgach rachunkowych, podatkowej księdze przychodów i rozchodów oraz ryczałcie. Dostarczamy szereg dogodnych rozwiązań online’owych, księgowych i internetowych. Reprezentujemy naszych klientów przed organami państwowymi. Pomagamy przy założeniu działalności, jak i rejestracji spółek prawa handlowego. Prowadzimy również kadry i płace. Oszczędzamy cenny czas - dojeżdżamy do klienta jedynie, gdy potrzebna jest pomoc na miejscu. Nasz dział informatyczny przeniesie Twoją firmę w bezpieczne miejsce na serwerze, do którego będzie miał dostęp zdalnie z dowolnego urządzenia i miejsca na świecie.",
    bdriveShort: "Blu Drive - Polski samochód elektryczny.",
    bdriveLong:
      "Działamy prężnie w przemyśle samochodowym, inicjując i uczestnicząc w nowoczesnych i rozwojowych projektach.",

    risks1Short: "Ryzyko konkurencji cenowej",
    risks1Long:
      "Duży wybór firm z zakresu oferowanych produktów informatycznych, co wpływa na szeroki wachlarz cenowy za ich usługi.",
    risks1Values:
      "Wartością Blu Holding jest kompleksowe oferowanie usług od audytu poprzez wdrożenie nowoczesnych rozwiązań kończąc na szkoleniach obsługi systemów za relatywnie niską cenę.",

    risks2Short: "Ryzyko podwyższonych kosztów realizacji",
    risks2Long:
      "Działamy na terenie Polski i Europy, co zwiększa wydatki na logistykę. Lokalna konkurencja może stanowić w tej przestrzeni zagrożenie.",
    risks2Values:
      "Blu Holding jest wypracowanie własnych procesów minimalizacji kosztów zleceń, poprzez ograniczenie do minimum realizację na miejscu u zleceniodawcy i zakończenie jej zdalnie przy pomocy wyspecjalizowanych narzędzi.",

    risks3Short: "Ryzyko opóźnienia oczekiwanych zysków z inwestycji",
    risks3Long:
      "Głównym determinantem na środowisko działania spółki są zmieniające się przepisy prawa, globalna polityka finansowa czy rozwój innowacyjnych technologii, co stanowi pewne zagrożenie w osiągnięciu postawionych celów spółki.",
    risks3Values:
      "Wartością Blu Holding jest optymalizacja procesów decyzyjnych poprzez wdrażanie innowacyjnych narzędzi monitorujących. Stworzone laboratorium analityczne pomaga maksymalizować oczekiwane wyniki finansowe organizacji.",

    risks4Short: "Ryzyko spóźnionej reakcji na rozwój technologii",
    risks4Long:
      "Branża IT jest bardzo wymagająca, a zjawisko pojawiających się nowych technologii niewspółmiernie przyspieszyło w stosunku do tego, co jeszcze obserwowaliśmy 10 czy 15 lat temu. Kilkuletnia, a więc przestarzała technologia może tracić zainteresowanie potencjalnych kontrahentów.",
    risks4Values:
      "Wartością Blu Holding jest ciągle rozwijająca się kadra pracownicza, inwestycje w szkolenia oraz uczestnictwo w eventach technologicznych, co podnosi poziom niezbędnej wiedzy, a wartością dodaną to współpracownicy - pasjonaci. Dziedzina, w której działa przedsiębiorstwo, to szeroko rozumiane innowacje i to Blu Holding właśnie je tworzy.",

    risks5Short: "Ryzyko wyceny spółki",
    risks5Long: "",
    risks5Values:
      "Blu Holding zleca zewnętrznej firmie specjalizującej się w wycenach spółek ze szczególnym uwzględnieniem nie przeszacowania wartości aktywów przedsiębiorstwa, inflacji, przydatność składników majątkowych, zróżnicowania cen oraz należności i zobowiązań.",

    risks6Short: "Ryzyko związane z pandemią",
    risks6Long: "",
    risks6Values:
      "Blu Holding realizuje zadania drogą elektroniczną. Głównymi produktami są systemy rozwiązań w sieci, a więc wykorzystywanie narzędzi eliminujących kontakt bezpośredni z klientem. Zlecenia te znacznie minimalizują wpływ pandemii na działalność spółki.",

    risks7Short: "Ryzyko nie pozyskania pełnej emisji kwoty emisji",
    risks7Long: "",
    risks7Values:
      "Blu Holding planuje inwestycje i działania pozwalające na uzyskanie pełnej kwoty. W przypadku pozyskania części finansowania, cele do osiągnięcia, co najwyżej wydłużą się w czasie.",

    risks8Short: "Ryzyko nie wypłacenia dywidend",
    risks8Long: "",
    risks8Values:
      "Wypłata oraz wysokość dywidend uzależniona jest od wyników finansowych osiąganych przez spółkę, a jak wynika z historii przedsiębiorstwa z roku na rok wartości te wzrastały. Spółka Blu Holding przewiduje zyski w kolejnych latach działalności i tym samym wypłacalność dywidend będzie wzrastać.",

    risks9Short: "Ryzyko utraty dobrego imienia marki i spółki",
    risks9Long: "",
    risks9Values:
      "Blu Holding wprowadził procedury standardów interakcji i obsługi klienta, a także relacji ze środowiskiem, również bardzo duży nacisk kładziony jest na kulturę zarządzania wewnątrz w firmie, co wielokrotnie zostało zauważone w kręgach biznesu i wyróżnione licznymi nagrodami.",

    investPlaceholder: "Inwestycja w obiecujacy startup",
    giveVoucherCode: "Aby przejść dalej, należy podać kod swojego vouchera.",
    goNext: "Przejdź dalej",
    getVoucherBenefit: "Wybierz swój voucher o wartości 1000zł",
    choose: "Wybieram",
    vpsserver: "Serwer VPS",
    SSLcertificate: "Certyfikat SSL",
    dataSpace: "Przestrzeń na Twoje dane o wartości 1000zł",
    performanceAudit: "Audyt wydajności",
    article: "Artykuł sponsorowany na BluStreamTV",
    banner: "Banner reklamowy na BluStreamTV na okres 1 miesiąca",
    logo: "Lokowanie logotypu w transmisji live zaplanowanego wydarzenia",
    pageNotFound: "Błąd 404: Strona nie została odnaleziona.",
    goToMain: "Wróć do strony głównej",
    history1:
      "Powstanie BluSoft związane było ze spostrzeżeniem, że w miarę jak technologia staje się coraz bardziej powszechna w biznesie i życiu codziennym, rośnie również potrzeba profesjonalnego wsparcia w obszarze informatyki, bezpieczeństwa danych oraz infrastruktury sieciowej. Z początku wykorzystując swoją wiedzę i kreatywność, pomagaliśmy naszym klientom radzić sobie z problemami sprzętowymi, oprogramowaniem oraz zagadnieniami związanymi z bezpieczeństwem danych. Z biegiem czasu zdobywaliśmy coraz większe zaufanie klientów. Nasze umiejętności w dziedzinie audytu bezpieczeństwa oraz wdrażania opieki informatycznej były coraz bardziej doceniane. W miarę jak technologia stawała się coraz bardziej skomplikowana, nasza firma rozwijała się razem z nią, zyskując wiedzę i doświadczenie w obszarze projektowania sieci, serwerowni oraz zabezpieczeń informatycznych. Dziś, po wielu latach działalności, jesteśmy dumą firmą z ugruntowaną pozycją na rynku informatycznym. Nasza renoma opiera się na rzetelności, profesjonalizmie oraz pasji do technologii. Wciąż doskonalimy nasze umiejętności, aby sprostać rosnącym wymaganiom rynku i zapewnić naszym klientom kompleksowe wsparcie w dziedzinie informatyki i technologii.",
    history2:
      "Firma informatyczna z wieloletnim doświadczeniem i renomą na rynku. Prowadzimy audyty bezpieczeństwa w przedsiębiorstwach i wdrażamy opiekę informatyczną. Przekaż w ręce specjalistów wszelkie problemy ze sprzętem. Z powodzeniem zajmujemy się projektowaniem sieci i serwerowni. Jeśli planujesz nową inwestycję przeprowadzimy Cię przez cały proces wdrożenia. Pokażemy Ci, jakie masz alternatywy, doradzimy co wybrać, a także dostarczymy niezbędny sprzęt - niezawodne serwery oraz komputery. Oferujemy również usługę hostingu oprogramowania dedykowanego, CRM, ERP, bądź innego, wymagającego nieprzerwanej pracy i niezawodnych zabezpieczeń. Nasza infrastruktura zapewnia bezawaryjną i ciągłą pracę. Codziennie wykonujemy kopie zapasowe powierzonych nam danych. Nieustannie monitorujemy nasze systemy, by wyeliminować zagrożenia w sieci i możliwość dostępu do wrażliwych danych osobom niepowołanym.",
    history3:
      "Usługa umożliwia nawiązanie zdalnego połączenia z wirtualnym serwerem. To coś więcej niż dysk w chmurze, Dropbox czy Google drive. Obrazowo, e-Pulpit24 to Twój cały komputer z Twoimi programami, aplikacjami i danymi, dostępny z dowolnego urządzenia i miejsca na całym świecie. E-pulpit24 to usługa, która umożliwia jednoczesną pracę wielu użytkowników znajdujących się w różnych lokalizacjach na jednym wirtualnym serwerze.. Takie rozwiązanie doskonale sprawdza się w organizacjach, gdzie kilku pracowników korzysta z jednej aplikacji i bazy danych. Kwestia bezpieczeństwa Twoich danych ma dla nas najwyższy priorytet. regularnie wykonujemy kopie bezpieczeństwa, w trybie ciągłym monitorujemy stan serwerów i urządzeń w naszym Data Center. Natychmiast reagujemy na wszelkie nieprawidłowości oraz świadczymy także usługi VPN dla pulpitu zdalnego dla bardziej wymagających osób.",
    history4:
      "Oferujemy niezawodny i stabilny hosting, wysoki poziom bezpieczeństwa danych oraz profesjonalną opiekę informatyczną. Hosting to usługa wynajmu przestrzeni dyskowej dzierżawionej z zasobów serwera. Każda strona internetowa, utrzymywana jest właśnie na serwerze, działającym nieprzerwanie i widocznym w Internecie. Zapewnia on tym samym przechowywanie i przetwarzanie wszystkich danych, z których korzysta strona. Nasza serwerownia wyposażona jest w wysokiej klasy serwery, macierze dyskowe oraz urządzenia sieciowe, których stan monitorowany jest 24 h na dobę. Wychodząc naprzeciw coraz to nowym zagrożeniom cyberbezpieczeństwa, wdrażamy i na bieżąco aktualizujemy systemy zabezpieczeń. Dzięki tym zabiegom gwarantujemy pełną dostępność oraz bezpieczeństwo danych i usług. Dysponujemy profesjonalnie wyszkoloną kadrą techników i inżynierów IT, która służy radą i pomocą w rozwiązaniu pojawiających się problemów. Jako certyfikowany partner NASK, rejestrujemy również domeny i pomagamy w ich zakupie.",
    history5:
      "Wspieramy klientów w prowadzeniu działalności gospodarczej, nie tylko od strony finansowej i rachunkowej, ale też kadrowej i księgowej. Jesteśmy doświadczonym zespołem, który wciąż podnosi swoje kompetencje w dziedzinie finansów, rachunkowości i prawa pracy. Świadczymy kompleksową obsługę dla wszystkich przedsiębiorców, których księgowość oparta jest na księgach rachunkowych, podatkowej księdze przychodów i rozchodów oraz ryczałcie. Dostarczamy szereg dogodnych rozwiązań online'owych, księgowych i internetowych. Reprezentujemy naszych klientów przed organami państwowymi. Pomagamy przy założeniu działalności, jak i rejestracji spółek prawa handlowego. Prowadzimy również kadry i płace. Oszczędzamy cenny czas - dojeżdżamy do klienta jedynie, gdy potrzebna jest pomoc na miejscu. Nasz dział informatyczny przeniesie Twoją firmę w bezpieczne miejsce na serwerze, do którego będzie miał dostęp zdalnie z dowolnego urządzenia i miejsca na świecie.",
    history6:
      "Jesteśmy firmą specjalizującą się w transmisjach na żywo oraz posiadającą własną platformę pay per view. Nasza oferta obejmuje rozległe możliwości promocji marek i wydarzeń na nieograniczoną skalę, docierając wszędzie tam, gdzie tylko sięga Internet. Nasz doświadczony zespół składający się z profesjonalnych operatorów kamer, montażystów i dźwiękowców gwarantuje kompleksową obsługę transmisji. Bazujemy na wieloletnim doświadczeniu oraz niezawodnym sprzęcie najwyższej jakości. Bez względu na to, czy chcesz transmitować konferencję biznesową, koncert, szkolenie, webinar czy zawody sportowe z różnych dyscyplin, jesteśmy gotowi sprostać Twoim oczekiwaniom. Oferujemy pełną obsługę wydarzeń, rozpoczynając od tworzenia scenariusza, poprzez promocję w sieci, konferencję prasową dla mediów, aż po realizację na miejscu. Nasze usługi obejmują obsługę studia live i usługę konferansjerską. Dodatkowo, udostępniamy zintegrowaną platformę PPV, umożliwiającą sprzedaż biletów online. Dzięki nam masz pewność, że Twoja transmisja audiowizualna będzie profesjonalna i dotrze do szerokiej publiczności online.",
    history6_1:
      "Powstanie marki BluStreamTV Academy wiąże się ściśle z rozwojem i sukcesem firmy BluStreamTV. która działała jako dynamiczna firma zajmująca się produkcją i transmisją wydarzeń na platformach streamingowych. W miarę rozwoju firmy, pojawiła się potrzeba posiadania wykwalifikowanego personelu, który byłby w stanie sprostać coraz bardziej wymagającym projektom i zapewnić najwyższą jakość transmisji. W związku z tym, postanowiono stworzyć oddzielną jednostkę edukacyjną – BluStreamTV Academy. Akademia skoncentrowała się na szkoleniu przyszłych operatorów kamer, realizatorów transmisji oraz montażystów wideo, a także redaktorów i dziennikarzy. Dzięki swojej bliskiej współpracy z główną firmą BluStreamTV, uczestnicy kursów mają możliwość zdobywania wiedzy praktycznej od doświadczonych profesjonalistów, którzy mieli na swoim koncie liczne udane projekty transmisyjne.",
    history7:
      "Jesteśmy agencją marketingową specjalizującą się w obsłudze mediów społecznościowych. Tworzymy kreatywne, przyciągające uwagę i wyróżniające się treści na portalach biznesowych w social mediach, takich jak Facebook, Instragram, LinkedIn i YouTube. Planujemy kampanie reklamowe, strategie i budżety. Jesteśmy zespołem, który w nieszablonowy sposób tworzy działania PR-owe i marketingowe. Posiadamy również wyspecjalizowanych copywriterów, mogących sprostać najwyższym oczekiwaniom. Dysponując profesjonalnym sprzętem filmowym oraz wykwalifikowaną grupą specjalistów tworzymy najwyższej jakości nagrania filmowe oraz materiały multimedialne. Zaufaj nam, a zadbamy o Twój wizerunek, aby zwiększyć świadomość marki wśród obecnych i nowych klientów. Udowodnimy, że obecność w social mediach to działania wizerunkowe, które przekładają się na realną sprzedaż i wyniki finansowe. Comiesięczny raport naszej pracy da Ci pełną kontrolę nad efektami prowadzonej kampanii.",
    history8:
      "Geneza powstania marki BluBusinessClub wiąże się z potrzebą współpracy i wzajemnego wsparcia przedsiębiorców z Bydgoszczy. W mieście tym skupiła się grupa osób z pasją do biznesu, które dostrzegły ogromną wartość płynącą ze wspólnego działania i wymiany doświadczeń. Wczesny okres marki BluBusinessClub to historia spotkań i rozmów między lokalnymi przedsiębiorcami, którzy zdawali sobie sprawę, że wzajemne wsparcie i wymiana wiedzy mogą przyczynić się do wzrostu ich firm. Zauważyli, że niekoniecznie muszą konkurować, lecz mogą czerpać wzajemne korzyści z dzielenia się swoimi umiejętnościami, pomysłami i perspektywami.Cotygodniowe spotkania stały się kluczowym elementem działalności BluBusinessClub. Pozwoliły one na regularną wymianę informacji na temat lokalnego rynku, trendów biznesowych i możliwości inwestycyjnych. To także doskonała okazja do nawiązywania relacji biznesowych i budowania sieci kontaktów.",
    history9:
      "Organizacja narodziła się z potrzeby dostarczenia skutecznych narzędzi i wskazania właściwych dróg dla start-upów oraz istniejących firm, by mogły rozwijać się i osiągać sukcesy w trudnym, ale dynamicznym środowisku biznesowym.Fundacja BluSpace została stworzona jako odpowiedź na potrzeby przedsiębiorców w obszarze Kujawsko-Pomorskiego. Jej celem była nie tylko pomoc w rozwijaniu firm, ale także stworzenie spójnej społeczności biznesowej, która mogłaby wymieniać się doświadczeniami, tworzyć grupę wsparcia oraz wspólnie działać na rzecz rozwoju lokalnej gospodarki. Zespół pracowników fundacji BluSpace, składający się z doświadczonych specjalistów i fachowców, został stworzony w celu dostarczenia kompleksowego wsparcia dla przedsiębiorców. Ich wiedza i doświadczenie obejmowały różne dziedziny, takie jak marketing, technologia, finanse czy prawo.",
    history10:
      "Blu Game Show narodziło się jako rezultat pasji do gier komputerowych i kultury gamingowej, która stopniowo ewoluowała w dynamiczne wydarzenie o znaczeniu zarówno rozrywkowym, jak i społecznościowym. Powstanie tej inicjatywy wiązało się z pragnieniem stworzenia przestrzeni, w której miłośnicy e-sportu, gier komputerowych oraz nowoczesnych technologii mogliby spotkać się, rywalizować i czerpać inspirację.Wydarzenie z każdym rokiem rozrasta się, zarówno jeśli chodzi o liczbę gier turniejowych jak i ofertę skierowaną do uczestników czy wystawców. Ważnym punktem eventu jest strefa biznesowa, która poprzez networking pozwala na nawiązywanie nowych wartościowych kontaktów biznesowych. Dzięki jego rozwojowi i wsparciu ze strony różnych instytucji, stało się ono istotnym elementem kultury gamingowej oraz platformą, na której społeczność e-sportu i technologicznego środowiska mogą się spotkać, rywalizować i wzajemnie inspirować.",
    fbgroup: "Dostęp do zamkniętej grupy inwestorów na FB",
    bgsTicket1:
      "Bilet na wydarzenie Blu Game Show dla jednej osoby ważny do końca roku 2024.",
    bgsTicket4:
      "Bilet na wydarzenie Blu Game Show dla czterech osób ważny do końca roku 2024.",
    vipSet:
      "Zestaw VIP Blu Game Show; koszulka, smycz, opaska, podkładka pod myszkę, pendrive, czapka.",
    investorStatue: "Statuetka inwestora.",
    epulpitFree5:
      "Możliwość z bezpłatnego dostępu do usługi e-Pulpit do 5 stanowisk na 30 dni.",
    bgsVip2024: "Strefa Vip na wydarzeniu Blu Game Show w 2024 roku.",
    productPlacement2024:
      "Lokowanie firmy podczas wydarzenia Blu Game Show w 2024 roku.",
    ppvAccess: "Dostęp do platformy PPV.",
    securityAudit: "Audyt niebezpieczeństwa.",
    trainings:
      "Możliwość z skorzystania dofinansowanych szkoleń za naszym pośrednictwem.",
    recommendations: "Referencje",
    awards: "Wyróżnienia",
    csr: "CSR",
    strategicInvestor: "Inwestorzy Strategiczni",
    patron: "Mecenasi Marki",
    investor: "Inwestorzy",
    crowdfounder: "Zaangażowani Inwestorzy",

    faq1title: "Czy mogę kupić akcje dla innej osoby, np. na prezent?",
    faq2title: "Czy spółka wydaje akcje w formie papierowej?",
    faq3title: "Kiedy będą wysyłane nagrody dodatkowe?",
    faq4title: "Czy spółka posiada zadłużenie?",
    faq5title: "Czy spółka posiada nieruchomości?",
    faq6title: "Czy mogę zainwestować więcej, niż raz?",
    faq7title: "Jakie dane muszę podać, jeżeli chcę zainwestować?",
    faq8title:
      "Co jeżeli chcę otrzymać nagrody na adres inny, niż adres zamieszkania?",
    faq9title: "Czy mogę zainwestować, jeśli mieszkam poza granicami Polski?",
    faq10title: "Czy muszę zapłacić podatek po zakupie akcji w tej emisji?",
    faq11title:
      "Czy mogę sprzedać swoje akcje? Czy muszę zapłacić podatek od sprzedanych akcji?",
    faq12title:
      "Dlaczego cena emisyjna akcji jest wyższa od jej wartości nominalnej?",
    faq13title:
      "Co się stanie, jeśli nie zostanie osiągnięty próg powodzenia emisji?",
    faq14title: "Czy objęcie akcji oznacza spotkanie z notariuszem?",
    faq15title: "Czy akcje spółki są notowane na giełdzie?",
    faq16title:
      "Urząd Komisji Nadzoru Finansowego o finansowaniu społecznościowym - crowdfundingu.",
    faq17title: "Jak została opracowana wycena pre-money Spółki?",
    youDontFound: "Nie znalazłeś odpowiedzi? Zapytaj.",
    forInvestors: "Dla inwestorów",
    forEmmiters: "Dla emitentów",
    docPatent: "Prawo ochronne na znak towarowy",
    faq1content1:
      "Budowanie świadomości finansowej i inwestycyjnej wśród bliskich to bardzo rozważny przyszłościowy krok i świetny pomysł. Niestety, regulacje prawne nie pozwalają na dokonywanie zakupu akcji na rzecz innej osoby. Zgodnie z obowiązującymi przepisami, konieczne jest nabycie akcji na własne nazwisko, a następnie możesz przekazać je w formie darowizny pisemnej twoim wnukom, rodzicom, małżonkowi, małżonce, dzieciom lub innej osobie bliskiej.",
    faq1content2: "",
    faq2content1:
      "Zgodnie z obowiązującym prawem, akcje muszą być zarejestrowane w elektronicznym rejestrze akcjonariuszy. Ten rejestr jest prowadzony w sposób zapewniający bezpieczeństwo i integralność przechowywanych danych. Informacje o akcjonariuszach są ujawniane w rejestrze dopiero po wpisie nowej emisji akcji do rejestru przedsiębiorców.",
    faq2content2:
      "Jeśli tego zażyczysz, możemy dostarczyć Ci dokument akcji w formie papierowej. Jednak ważne jest, aby zrozumieć, że taki dokument ma charakter wyłącznie symboliczny i nie przysługują Ci żadne prawa wynikające z rzeczywistych akcji, które są zarejestrowane w elektronicznym rejestrze akcjonariuszy.",
    faq3content1: "", // TODO potrzebujemy informacji o nagrodach
    faq3content2: "",
    faq4content1: "", // TODO potrzebuje danych
    faq4content2: "",
    faq5content1: "", // TODO potrzebuje danych
    faq5content2: "",
    faq6content1:
      "Naturalnie, inwestycje są agregowane, co prowadzi do przyznania akcji na podstawie całkowitej, ostatecznej sumy.",
    faq6content2: "",
    faq7content1:
      "Należy podać pełne imię i nazwisko z uwzględnieniem polskich znaków (zgodnie z danymi z dowodu osobistego), a także datę urodzenia. Konieczne jest także podanie adresu, jednak nie musi być to adres zamieszkania.",
    faq7content2: "",
    faq8content1:
      "Podczas nabywania akcji, prosimy o wskazanie adresu, na który mają zostać wysłane ewentualne nagrody.",
    faq8content2: "",
    faq9content1:
      "Oczywiście! Jeśli jesteś inwestorem spoza kraju, prosimy o wybór płatności przelewem SEPA Single Euro Payments Area w systemie tPay. Następnie wybierz swoje państwo i dokonaj przelewu zgodnie z instrukcjami operatora płatności.",
    faq9content2: "",
    faq10content1: "Nie. ",
    faq10content2: "",
    faq11content1:
      "Oczywiście. Akcje stanowią przedmiot, którym możesz swobodnie handlować. W celu dokonania transakcji zakupu lub sprzedaży akcji, zawierasz umowę, a następnie informujesz spółkę o koniecznych zmianach w rejestrze akcjonariuszy.",
    faq11content2:
      "W przypadku osiągnięcia zysku z transakcji sprzedaży akcji - to znaczy, jeśli cena sprzedaży przewyższa cenę zakupu - konieczne będzie opłacenie podatku w wysokości 19% od uzyskanej różnicy.",
    faq12content1:
      "Wartość nominalna akcji jest oparta na początkowym kapitale spółki i niekoniecznie odzwierciedla jej obecną wartość rynkową. Wycena rynkowa spółki zależy od różnych czynników, takich jak wyniki finansowe, aktywa i pasywa, perspektywy wzrostu, otoczenie rynkowe oraz kompetencje zespołu zarządzającego.",
    faq12content2: "",
    faq13content1:
      "Inwestorom zostaną zwrócone środki, które wpłacili. Szczegóły dotyczące procesu zwrotu są sprecyzowane w regulaminie (możesz znaleźć go pod linkiem w stopce).",
    faq13content2: "",
    faq14content1: "Nie.",
    faq14content2: "",
    faq15content1:
      "Spółka nie figuruje na giełdzie, a jej akcje nie są dostępne na rynku publicznym. Niemniej, jeśli spółka zdecyduje się w przyszłości na debiut na NewConnect lub GPW, akcje zostaną przeniesione na rachunek maklerski w związku z tym procesem.", // TODO NIE WIEM czy to dobrze - komentarz autora tekstu
    faq15content2: "",
    faq16content1:
      "BluHolding opiera swoją ofertę emisji akcji na zasadach rekomendowanych przez Komisję Nadzoru Finansowego. Serdecznie zachęcamy do zapoznania się z dostępnymi materiałami przygotowanymi przez KNF dotyczącymi crowdfundingu, emitentów oraz inwestorów:", // TODO custom list element
    faq16content2:
      "Mając to na uwadze, serdecznie zachęcamy Państwa do zgłębienia tych materiałów, które stanowią cenne źródło wiedzy związanej z procesem inwestowania, finansowania spółek oraz aspektami prawno-finansowymi crowdfundingu.",
    faq17content1:
      "Dla ustalenia wartości firmy przeprowadzono dwie niezależne wyceny. Jedną z nich zlecono specjalistycznej firmie zewnętrznej, a drugą przeprowadził wewnętrznie Prezes Zarządu. Obydwie metody uwzględniły zarówno dane historyczne, jak i prognozy finansowe, aby stworzyć kompleksowy obraz wartości przedsiębiorstwa.", // TODO parafraza z Kolejkowa,u nas mają być 3 wyceny - poprawię jak zadecydujemy finalnie
    faq17content2: "",
    bpsiShort:
      "Renomowana firma specjalizująca się w dostarczaniu wysokiej jakości certyfikowanych szkoleń i kursów w różnych obszarach zawodowych.",
    bpsiLong:
      "Działalność BLU PROFESSIONAL SKILLS INSTITUTE obejmuje szkolenia z zakresu technologii informacyjno-komunikacyjnych (IT), umiejętności operatorskich dla kamerzystów, kompetencji dziennikarskich oraz zagadnień związanych z marketingiem. Założenie Blu Professional Skills Institute miało miejsce w odpowiedzi na rosnące zapotrzebowanie na wykwalifikowanych specjalistów w wymienionych dziedzinach. Firma została stworzona przez doświadczonych ekspertów i edukatorów- praktyków, którzy zdawali sobie sprawę z konieczności dostarczenia kompleksowej edukacji, która odpowiada na rosnące wyzwania rynku pracy i biznesu.",
    history_bpsi:
      "Jednym z kluczowych momentów w powstawaniu BPSI było zrozumienie, że rozwój branży IT oraz nowoczesnych technologii  idzie w parze z zapotrzebowaniem na specjalistów o unikalnych umiejętnościach. Wiedza z zakresu programowania, analizy danych, projektowania stron internetowych czy obsługi sprzętu streamingowego stawała się coraz bardziej ceniona, ale brakowało skonkretyzowanych programów edukacyjnych, które byłyby dostosowane do potrzeb tych dziedzin. Dlatego założyciele BPSI postanowili stworzyć instytucję, która skupiałaby się na dostarczaniu praktycznej wiedzy i umiejętności, a nie tylko teorii. Inspiracją był fakt, że osoby z branży IT, marketingu czy telewizji streamingowej często potrzebują specjalistycznych kompetencji, których nie zawsze można się nauczyć na ogólnych szkoleniach czy uniwersytetach.",
    history_bluoffice:
      "Geneza BluOffice była również odpowiedzią na potrzebę oddzielenia adresu zamieszkania od adresu siedziby firmy, co często jest wymagane w działalności biznesowej. Również otwarcie nowego oddziału firmy stwarzało wyzwania związane z siedzibą, a wirtualny adres wydawał się idealnym rozwiązaniem. BluOffice odpowiada na potrzebę dostarczenia firmom elastycznych, ekonomicznych i prestiżowych rozwiązań w zakresie prowadzenia działalności i siedziby. Założyciele zrozumieli, że istnieje popyt na usługę wirtualnego biura, która pozwoli firmom skupić się na biznesie, jednocześnie unikając wysokich kosztów tradycyjnych biur. Dzięki temu BluOffice stało się innowacyjnym rozwiązaniem, które służyło firmom w Bydgoszczy i okolicach.",
    infoSoon: "Informacje wkrótce",
    name: "Imie i nazwisko",
    formName: "Nazwa / imię i nazwisko",
    formEmail: "Adres e-mail",
    formPhone: "Numer telefonu",
    formMsg: "Treść wiadomości",
    formBtn: "Wyślij",
    menuMobileOpen: "Otwórz menu",
    menuMobileClose: "Zamknij menu",
    blumilShort:
      "BluHolding jest partnerem organizacyjnym Targów LOGIS-MIL. Edycja 2024 odbyła się 13-14 czerwca 2024 w Bydgoszczy. Trwają przygotowania do LOGIS-MIL 2025",
    blumilLong: `
    Targi LOGIS-MIL to doskonała okazja do zaprezentowania oferty kierowanej do sektora służb mundurowych. Wydarzenie powstało przede wszystkim z myślą o wystawcach. Są dla nich sprawdzoną i skuteczną formą budowania relacji z osobami decyzyjnymi w łańcuchu dostaw, ekspertami, dowódcami, żołnierzami oraz funkcjonariuszami jednostek mundurowych. Już teraz zapraszamy do udziału w LOGIS-MIL 2025, które zaplanowano na czerwiec 2025 r. BluHolding wspiera w organizacji wydarzenia Stowarzyszenie Dostawców na rzecz Służb Mundurowych oraz jest twórcą CyberMil - pionu Targów dedykowanemu IT, cyberbezpieczeństwu i nowym technologiom.<br /><br /><u><a href="https://cybermil.bluholding.pl">Więcej o LOGIS-MIL</a></u>`,
  },
};

export default PL;
