import React from "react";
import { withTranslation } from "react-i18next";
import Header from "./../../components/Header/Header";
import "./History.sass";
import Bluholding from "./bluholding_black.webp";
import Footer from "../../components/Footer/Footer";

class History extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <Header />
        <h1 className="History__title">{t("companyHistory")}</h1>
        <div className="History">
          <div className="History__content">
            <h2>BluSoft</h2>
            <p className="History__content__date">02.10.2015</p>
            <p className="History__content__text">{t("history1")}</p>
          </div>
          <div className="History__content">
            <h2>BluIT</h2>
            <p className="History__content__text">{t("history2")}</p>
          </div>
          <div className="History__content">
            <h2>e-Pulpit24</h2>
            <p className="History__content__text">{t("history3")}</p>
          </div>
          <div className="History__content">
            <h2>BluSerwer</h2>
            <p className="History__content__text">{t("history4")}</p>
          </div>
          <div className="History__content">
            <h2>Blu-eM</h2>
            <p className="History__content__date">11.11.2016</p>
            <p className="History__content__text">{t("history5")}</p>
          </div>
          <div className="History__content">
            <h2>BluOffice</h2>
            <p className="History__content__text">{t("history_bluoffice")}</p>
          </div>
          <div className="History__content">
            <h2>BluStreamTV</h2>
            <p className="History__content__date">04.12.2020</p>
            <p className="History__content__text">{t("history6")}</p>
          </div>
          <div className="History__content">
            <h2>BTV BluStreamTV Academy</h2>
            <p className="History__content__date">19.12.2021</p>
            <p className="History__content__text">{t("history6_1")}</p>
          </div>
          <div className="History__content">
            <h2>AdShoot</h2>
            <p className="History__content__text">{t("history7")}</p>
          </div>
          <div className="History__content">
            <h2>BluBusinessClub</h2>
            <p className="History__content__date">06.2021</p>
            <p className="History__content__text">{t("history8")}</p>
          </div>
          <div className="History__content">
            <h2>Blu Game Show</h2>
            <p className="History__content__date">19.12.2022</p>
            <p className="History__content__text">{t("history10")}</p>
          </div>
          <div className="History__content">
            <h2>BluSpace</h2>
            <p className="History__content__date">30.12.2022</p>
            <p className="History__content__text">{t("history9")}</p>
          </div>
          <div className="History__content">
            <h2>Blu Professional Skills Institute</h2>
            <p className="History__content__date">9.08.2023</p>
            <p className="History__content__text">{t("history_bpsi")}</p>
            <div className="History__arrow"></div>
          </div>
          <div className="History__image-container">
            <img src={Bluholding} alt="Bluholding" />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withTranslation()(History);
