const EN = {
  translation: {
    aboutUs: "About us",
    aboutProject: "About project",
    trustedUs: "Trusted us",
    ourBrands: "Brands we invest in",
    ourHistory: "History",
    voucher: "Voucher",
    contact: "Contact",
    content1par1:
      "We are a capital group that brings together business entities operating at various levels in the IT and cyber security, financial, training, marketing and new media sectors. Thanks to the use of the latest technologies and more than 20 years of experience, we are able to combine proven solutions with innovation in the IT and management industry. We create optimal conditions during project implementation with the latest solutions, including the use of security systems and artificial intelligence technologies. We assist in obtaining EU, external and investor funding for software, hardware and development and training. The company actively supports various business, social and charitable initiatives, for which it has been repeatedly recognized.",
    content1par2:
      "As a leader in innovation, BluHolding brings together outstanding brands to form a synergistic holding company. Our goal is to accelerate technological progress through research, analysis, and the use of cutting-edge solutions to enrich enterprise offerings, and expand our customer base on a global scale.",
    content1par3:
      "We encourage partners and investors to join our group of shareholders. By acquiring BluHolding shares, you become an integral part of our achievements and future successes. By investing in our diversified brands, you have the opportunity to participate in groundbreaking technology projects.",
    content1par4:
      "We invite you to learn more about the structure of BluHolding and the areas of activity of the affiliated brands.",
    content1par5: "",
    boss: "Krzysztof Błaszkiewicz",
    bossTitle: "",
    content2par1:
      "BluHolding is a capital group operating in the new technology, financial and media industries.",
    content2par2: "WE OPERATE LOCALLY WE REACH GLOBALLY.",
    whatIs: "What is",
    inNumbers: "in numbers",
    becomeAShareHolder: "BECOME A INVESTOR",
    emissionProgress: "emission progress",
    d01012023: "2023-02-10",
    startDate: "start date",
    project: "Project",
    inProgress: "in progress",
    investments: "Number of investors",
    rate: "Rate of return (ROE for 2023). ",
    successTreshold: "success treshold",
    projectDesc: "Project description",
    investPackage: "Investor package",
    discuss: "FAQ",
    documents: "Documents",
    docPrivacyPolicy: "Privacy Policy",
    docTrademark: "Trademark protection rights",
    docRZIS: "RZIS last 12 months",
    docValuation: "BluHolding's valuation",
    docCashFlows: "Statement of Cash Flows",
    docInformation: "Information document",
    docAnnouncement: "Announcement",
    docRODO: "RODO",
    docKRS: "KRS",
    docBalance: "Balance sheet 2023",
    investList: "Investors list",
    risks: "Risks",
    infoAbout: "Informations about",
    startWriting: "Start writing",
    logIn: "Log in",
    sendComment: "SEND COMMENT",
    lessThanHour: "less than hour ago",
    years: "years ago",
    year: "year ago",
    day: "day ago",
    days: "days ago",
    hours: "hours ago",
    nameHidden: "Data hidden",
    more: "MORE",
    less: "LESS",
    partners: "Partners",
    ourInvestments: "Our investments",
    invest1Title: "We invest in people",
    invest1Content:
      "New jobs - employment growth | Certified training and courses for employees - expanding competencies and qualifications | Specialist training for technicians | Internships and apprenticeships - potential for future employees | Chill zone - comfort at work means better results and effective task completion | Modernization of workstations - new hardware, software updates | Expansion of the network of representatives and company fleet",
    invest2Title: "New technologies",
    invest2Content:
      "Research center - new research and training center | Research on the use of AI artificial intelligence in various fields | Laboratories - dedicated stations for experiments | Development of online broadcasting and streaming technology | Automotive - electric car sector",
    invest3Title: "Security",
    invest3Content:
      "Cyber security - training and education is to increase self-awareness of users of IT systems | Security audits - we protect the data of Polish and foreign entities | Expertise and analysis of results - continuous self-improvement and development | Monitoring and system notifications",
    invest4Title: "Product and organization development",
    invest4Content:
      "BluStreamTV professional television studio | Data centre modernisation - Server infrastructure development | Magento hosting, resource scaling - horizontal and vertical | Data security - cloud product development | Automation and optimization of company processes - ISO standards",
    invest5Title: "Investing in the future",
    invest5Content:
      "Training center - modern training and conference rooms | Coworking areas - more opportunities and business leads | Attested office equipment - health and safety standards | Professional equipment for teleconferencing and online learning | Interactive online training platform",
    value: "Value",
    from: "from",
    shares: "shares",
    companyHistory: "Company history",
    epulpitShort:
      "The service allows you to establish a remote connection to a virtual server.",
    epulpitLong:
      "The service allows you to establish a remote connection to a virtual server. It is more than a cloud drive, Dropbox or Google drive. Figuratively speaking, e-Pulpit24 is your entire computer with your programmes, applications and data, accessible from any device and location around the world. e-Pulpit24 is a service that allows multiple users located in different locations to work simultaneously on a single virtual server. Such a solution is ideal for organisations where several employees use a single application and database. The security of your data is a top priority for us. We regularly make security copies and continuously monitor the condition of servers and devices in our Data Centre. We respond immediately to any irregularities and also provide VPN services for remote desktop for more demanding users.",
    blusoftShort: "IT services integrator.",
    blusoftLong:
      "IT company with many years of experience and reputation in the market. We conduct security audits of companies and implement IT care. Put any hardware problems in the hands of specialists. We successfully deal with the design of networks and server rooms. If you are planning a new investment we will guide you through the entire implementation process. We will show you what alternatives you have, advise you on what to choose, and provide you with the necessary hardware - reliable servers and computers. We also offer a hosting service for dedicated software, CRM, ERP or any other software requiring uninterrupted operation and reliable security. Our infrastructure ensures failure-free and continuous operation. We perform daily backups of the data entrusted to us. We continuously monitor our systems to eliminate network threats and the possibility of unauthorised access to sensitive data.",
    bluserwerShort: "Hosting, domains, data center.",
    bluserwerLong:
      "We offer reliable and stable hosting, a high level of data security and professional IT support. Hosting is a service of renting disk space leased from server resources. Every website is hosted on a server, which operates continuously and is visible on the Internet. It thus ensures the storage and processing of all data used by the website. Our server room is equipped with high-end servers, disk arrays and network equipment, the status of which is monitored 24 hours a day. To meet the ever-increasing cyber security threats, we implement and continuously update security systems. Thanks to these measures, we guarantee full availability and security of data and services. We have a professionally trained staff of IT technicians and engineers who can provide advice and assistance in solving any problems that may arise. As a certified NASK partner, we also register domains and assist in their purchase.",
    blusignShort: "Digital signature.",
    blusignLong:
      "The electronic signature is a tool that enables the identification of entities sending documents electronically. The submission of electronic forms to the authorities is gaining popularity among entrepreneurs, as it offers the possibility to save time. More and more industries are opening up to this form of cooperation. Thanks to the electronic signature, it is possible to tender for local and government administration JEDZ It also enables the online exchange of documents with institutions such as ZUS, Tax Office, Customs Office, KRS, etc. The use of an electronic signature is a significant convenience - thanks to this service, you gain the possibility to remotely conclude civil law contracts or send invoices, and this is increasingly becoming a requirement. Particular professional groups have to adapt to the standards of electronic record-keeping, which requires a qualified signature: healthcare, judiciary, prisons. Choose the most favourable version of electronic signature from our service catalogue and you will see how convenient it is!",
    blustreamtvShort: "TV production, live broadcast, PPV platform.",
    blustreamtvLong:
      "Realiser of live broadcasts and owner of the PPV platform. We offer a wide range of possibilities for promoting a brand or event on an unlimited scale. We reach everywhere the Internet reaches. A professional team of camera operators, editors and sound engineers provides comprehensive transmission services, based on many years of experience and reliable equipment of the highest quality. We will provide audiovisual transmission of business conferences, concerts, training sessions, webinars and sports competitions from all possible disciplines. We organise the full service of events, starting with the creation of the scenario, through online promotion, press conference for the media, up to on-site implementation - live studio support, announcer. In addition, we offer the possibility of using an integrated pay-per-view platform, providing the opportunity to sell tickets online.",
    blustreamacadShort: "Training of operators and journalists.",
    blustreamacadLong:
      "We are an academy for training future camera operators, broadcast producers and video editors. Our qualified staff, experienced in many broadcast productions, share their knowledge with the course participants. A unique and unrepeatable chance to practise skills in practice, during real productions, events, business conferences, as well as sporting events. We have our own well-equipped TV studio. BluStreamTV Academy provides you with the opportunity to work with professional equipment and software and gain industry-recognised work experience.",
    adshootShort: "Media house, marketing agency, creative agency.",
    adshootLong:
      "We are a marketing agency specialising in social media. We produce creative, attention-grabbing and distinctive content for business social media platforms such as Facebook, Instragram, LinkedIn and YouTube.... We plan advertising campaigns, strategies and budgets. We are a team that creates PR and marketing activities out of the box. We also have specialist copywriters who can meet the highest expectations. With professional film equipment and a qualified group of specialists, we create the highest quality film footage and multimedia materials. Trust us and we will take care of your image to increase brand awareness among existing and new customers. We will prove that social media presence is an image activity that translates into real sales and financial results. A monthly report of our work will give you full control over the effects of your campaign.",
    fundacjaShort: "Start-up, training, business space.",
    fundacjaLong:
      "The organisation's mission is to show the way to the development of start-ups, existing businesses and to support local business operating in the Kujawsko-Pomorskie Voivodeship. The BluSpace Foundation helps many entrepreneurs reach for tools that make their work easier or accelerate the development of their companies. The team of specialists and professionals working for the foundation, with many years of experience in their fields, helps to develop the competencies of entrepreneurs in terms of networking, exchange of experience, building a local business support group, as well as using the latest technologies. Our experts are able to provide comprehensive advice and service to companies, starting from the creation of a brand promotion strategy, through a logo or website, tax advice, to acquiring the first customers and maintaining a lasting business relationship with them.",
    bgsShort: "E-sports tournament, expo, Job Fair, Business Zone.",
    bgsLong:
      "Blu Game Show is an ever-growing gaming event that has received broad support from both institutions and the gaming community. We can boast the honorary patronages of the Ministry of Sport and Tourism, the Marshal of the Kujawsko-Pomorskie Voivodeship and the Mayor of Bydgoszcz, which testify to the importance that Blu Game Show has gained in the world of e-sports and gaming culture. Our event is not only an exciting CS:GO and FIFA tournament, but also a great trade fair with a wide range of attractions. Exhibitors from the IT, high-tech, fantasy and fandom sectors will be waiting for participants. In addition, there will be plenty of space for fans of anime, manga and a special Blu Game Show Zone, which is the perfect space for networking and business talks. This is not only an event for gaming enthusiasts, but also a great opportunity to make valuable business contacts, exchange experiences and discover new opportunities in the IT and high-tech industry.",
    bbcShort: "Business meetings, investment market.",
    bbcLong:
      "We are a group of entrepreneurs from Bydgoszcz who support each other's development of their own companies. We hold weekly meetings to keep up to date with the business situation on the local market. We constantly analyse investment opportunities and improve our competences. We are involved in projects that contribute to the promotion of the city and local business, thus strengthening the region's position on the Polish and international markets.",
    bofficeShort:
      "A virtual office is the most cost-effective form of doing business in an attractive location in Bydgoszcz.",
    bofficeLong:
      "A virtual office is the most economical form of conducting business in an attractive location in Bydgoszcz. Our offer is aimed at existing companies or people intending to open their own business. Thanks to BluOffice, you can run your business in the city centre without the need to rent expensive office space and sign a long-term contract. Do you need to separate your home address from your business address? Want to open a new branch office? Take advantage of a virtual address! Our office is located in the Bartodzieje housing estate in Bydgoszcz. Having your company headquarters in the city centre increases both the prestige and credibility of your company.",
    bemShort: "Finance and accounting, bookkeeping, human resources.",
    bemLong:
      "We support clients in running their business, not only on the financial and accounting side, but also on the human resources and accounting side. We are an experienced team that continues to improve its competence in the fields of finance, accounting and labour law. We provide a comprehensive service for all entrepreneurs whose bookkeeping is based on ledgers, income and expenditure tax book and lump sum. We provide a range of convenient online, bookkeeping and Internet solutions. We represent our clients before state authorities. We assist with the establishment of businesses, as well as the registration of commercial companies. We also handle human resources and payroll. We save valuable time - we only travel to the client when on-site assistance is needed. Our IT department will move your company to a secure location on a server, which can be accessed remotely from any device and anywhere in the world.",
    bdriveShort: "Blu Drive - Polish electric car.",
    bdriveLong:
      "We are active in the automotive industry, initiating and participating in modern and developmental projects.",

    risks1Short: "Risk of price competition",
    risks1Long:
      "Two valuations were carried out to estimate the value of the company. One was commissioned from an external company and the other was carried out internally by the CEO. Both valuations took into account both historical data and financial projections. Please refer to the valuations.",
    risks1Values:
      "The value of Blu Holding is to offer comprehensive services from audit through implementation of modern solutions to training in the use of the systems at a relatively low price.",

    risks2Short: "Risk of increased implementation costs",
    risks2Long:
      "We operate in Poland and Europe, which increases logistics expenses. Local competition may pose a risk in this space.",
    risks2Values:
      "Blu Holding is developing its own processes to minimise the cost of orders by minimising on-site execution at the client's premises and completing it remotely using specialised tools.",

    risks3Short: "Risk of delaying expected investment returns",
    risks3Long:
      "The main determinants on the company's operating environment are changing legislation, global financial policy or the development of innovative technologies, which poses some risk to the achievement of the company's stated objectives.",
    risks3Values:
      "Blu Holding's value is to optimise decision-making processes by implementing innovative monitoring tools. The analytical laboratory created helps to maximise the expected financial results of the organisation.",

    risks4Short: "Risk of late response to technological developments",
    risks4Long:
      "The IT industry is very demanding and the phenomenon of emerging new technologies has disproportionately accelerated compared to what we were still seeing 10 or 15 years ago. Technology that is several years old and therefore obsolete may lose the interest of potential contractors.",
    risks4Values:
      "Blu Holding's value is its constantly developing staff, investment in training and participation in technological events, which raises the level of necessary knowledge, and its added value is its co-workers - passionate people. The field in which the company operates is innovation in the broadest sense, and it is Blu Holding that creates it.",

    risks5Short: "Company valuation risk",
    risks5Long: "",
    risks5Values:
      "Blu Holding commissions an external company specialising in company valuations with a particular focus on non-revaluation of company assets, inflation, asset suitability, price differentials and receivables and payables.",

    risks6Short: "Pandemic risk",
    risks6Long: "",
    risks6Values:
      "Blu Holding carries out tasks electronically. The main products are web-based solution systems, thus using tools that eliminate direct contact with the customer. These assignments significantly minimise the impact of pandemics on the company's operations.",

    risks7Short: "Risk of not raising the full issue amount",
    risks7Long: "",
    risks7Values:
      "Blu Holding has put in place procedures for standards of interaction and customer service, as well as relations with the environment, also a very strong emphasis is placed on the management culture within the company, which has been repeatedly recognised in business circles and honoured with numerous awards.",

    risks8Short: "Risk of non-payment of dividends",
    risks8Long: "",
    risks8Values:
      "The payment and the amount of dividends depend on the financial performance of the company and, as the company's history shows, these values have increased from year to year. Blu Holding anticipates profits in the following years of operation and thus the dividend payout will increase.",

    risks9Short: "Risk of loss of brand and company reputation",
    risks9Long: "",
    risks9Values:
      "Blu Holding has put in place procedures for standards of interaction and customer service, as well as relations with the environment, also a very strong emphasis is placed on the management culture within the company, which has been repeatedly recognised in business circles and honoured with numerous awards.",

    investPlaceholder: "Investment in a promising start-up",
    giveVoucherCode: "You will need to enter your voucher code to proceed.",
    goNext: "go next",
    getVoucherBenefit: "Choose your 1,000 PLN voucher",
    choose: "choose",
    vpsserver: "VPS server",
    SSLcertificate: "SSL certificate",
    dataSpace: "Space for your data worth PLN 1,000",
    performanceAudit: "Performance audit",
    article: "Sponsored article on BluStreamTV",
    banner: "Banner ad on BluStreamTV for a period of 1 month",
    logo: "Logo placement in the live broadcast of a scheduled event",
    pageNotFound: "Error 404: The page was not found.",
    goToMain: "Return to main page",
    history1:
      "The establishment of BluSoft was related to the observation that as technology becomes more and more prevalent in business and everyday life, the need for professional support in the areas of IT, data security and network infrastructure is also growing. In the beginning, we used our knowledge and creativity to help our customers deal with hardware problems, software and data security issues. Over time, we gained more and more trust from our clients. Our skills in security auditing and IT care implementation were increasingly appreciated. As technology became more complex, our company grew with it, gaining knowledge and experience in the areas of network design, server rooms and IT security. Today, after many years in business, we are a proudly established company in the IT market. Our reputation is based on reliability, professionalism and a passion for technology. We continue to hone our skills to meet the growing demands of the market and provide our customers with comprehensive IT and technology support.",
    history2:
      "IT company with many years of experience and reputation in the market. We conduct security audits of companies and implement IT care. Put any hardware problems in the hands of specialists. We successfully deal with the design of networks and server rooms. If you are planning a new investment we will guide you through the entire implementation process. We will show you what alternatives you have, advise you on what to choose, and provide you with the necessary hardware - reliable servers and computers. We also offer a hosting service for dedicated software, CRM, ERP or any other software requiring uninterrupted operation and reliable security. Our infrastructure ensures failure-free and continuous operation. We perform daily backups of the data entrusted to us. We continuously monitor our systems to eliminate network threats and the possibility of unauthorised access to sensitive data.",
    history3:
      "The service allows you to establish a remote connection to a virtual server. It is more than a cloud drive, Dropbox or Google drive. Figuratively speaking, e-Desktop24 is your entire computer with your programmes, applications and data, accessible from any device and location around the world. E-Desktop24 is a service that allows multiple users located in different locations to work simultaneously on a single virtual server. Such a solution is ideal for organisations where several employees use a single application and database. The security of your data is a top priority for us. We regularly make security copies and continuously monitor the condition of servers and devices in our Data Centre. We respond immediately to any irregularities and also provide VPN services for remote desktop for more demanding users.",
    history4:
      "We offer reliable and stable hosting, a high level of data security and professional IT support. Hosting is a service of renting disk space leased from server resources. Every website is hosted on a server, which operates continuously and is visible on the Internet. It thus ensures the storage and processing of all data used by the website. Our server room is equipped with high-end servers, disk arrays and network equipment, the status of which is monitored 24 hours a day. To meet the ever-increasing cyber security threats, we implement and continuously update security systems. Thanks to these measures, we guarantee full availability and security of data and services. We have a professionally trained staff of IT technicians and engineers who can provide advice and assistance in solving any problems that may arise. As a certified NASK partner, we also register domains and assist in their purchase.",
    history5:
      "We support clients in running their business, not only on the financial and accounting side, but also on the human resources and accounting side. We are an experienced team that continues to improve its competence in the fields of finance, accounting and labour law. We provide a comprehensive service for all entrepreneurs whose bookkeeping is based on ledgers, income and expenditure tax book and lump sum. We provide a range of convenient online, bookkeeping and Internet solutions. We represent our clients before state authorities. We assist with the establishment of businesses, as well as the registration of commercial companies. We also handle human resources and payroll. We save valuable time - we only travel to the client when on-site assistance is needed. Our IT department will move your company to a secure location on a server, which can be accessed remotely from any device and anywhere in the world.",
    history6:
      "We are a company specializing in live broadcasts and having our own pay-per-view platform. Our offer includes extensive opportunities to promote brands and events on an unlimited scale, reaching everywhere the Internet reaches. Our experienced team of professional camera operators, editors and sound engineers guarantees comprehensive broadcasting services. We rely on years of experience and reliable equipment of the highest quality. Whether you want to broadcast a business conference, concert, training, webinar or sports competition from various disciplines, we are ready to meet your expectations. We offer full service for events, starting with script development, web promotion, media press conference and on-site execution. Our services include live studio support and an announcer service. In addition, we provide an integrated PPV platform for online ticket sales. With us, you can be sure that your audiovisual broadcast will be professional and reach a wide audience online.",
    history6_1:
      "The creation of the BluStreamTV Academy brand is closely linked to the growth and success of BluStreamTV. which operated as a dynamic company dedicated to producing and broadcasting events on streaming platforms. As the company grew, there was a need for qualified personnel who could handle increasingly demanding projects and ensure the highest quality of broadcasting. As a result, it was decided to create a separate educational unit - BluStreamTV Academy. The Academy focused on training future camera operators, broadcast producers and video editors, as well as editors and journalists. Thanks to its close cooperation with the main BluStreamTV company, course participants have the opportunity to gain practical knowledge from experienced professionals who have had numerous successful broadcast projects.",
    history7:
      "We are a marketing agency specialising in social media. We produce creative, attention-grabbing and distinctive content for business social media platforms such as Facebook, Instragram, LinkedIn and YouTube. We plan advertising campaigns, strategies and budgets. We are a team that creates PR and marketing activities out of the box. We also have specialised copywriters who can meet the highest expectations. With professional film equipment and a qualified group of specialists, we create the highest quality film footage and multimedia materials. Trust us and we will take care of your image to increase brand awareness among existing and new customers. We will prove that social media presence is an image activity that translates into real sales and financial results. A monthly report of our work will give you full control over the effects of your campaign.",
    history8:
      "The genesis of the BluBusinessClub brand is related to the need for cooperation and mutual support of entrepreneurs in Bydgoszcz. The city brought together a group of people with a passion for business, who saw tremendous value in working together and sharing experiences. The early days of the BluBusinessClub brand were a history of meetings and conversations between local entrepreneurs, who realized that mutual support and knowledge exchange could contribute to the growth of their businesses. They saw that they didn't necessarily have to compete, but could mutually benefit from sharing their skills, ideas and perspectives.Weekly meetings became a key part of BluBusinessClub's activities. They have allowed regular exchange of information on the local market, business trends and investment opportunities. They are also an excellent opportunity to establish business relationships and build networks.",
    history9:
      "The organization was born out of the need to provide effective tools and show the right paths for start-ups and existing companies to grow and succeed in a difficult but dynamic business environment.The BluSpace Foundation was created as a response to the needs of entrepreneurs in the Kujawsko-Pomorskie area. Its goal was not only to help businesses grow, but also to create a cohesive business community that could exchange experiences, form a support group and work together to develop the local economy. The BluSpace Foundation's staff team of experienced professionals and specialists was created to provide comprehensive support to entrepreneurs. Their knowledge and experience covered various fields, such as marketing, technology, finance and law.",
    history10:
      "The Blu Game Show was born as a result of a passion for computer games and gaming culture, which gradually evolved into a dynamic event with both entertainment and community significance. The creation of this initiative was related to the desire to create a space where lovers of e-sports, computer games and modern technologies could meet, compete and be inspired.The event is growing every year, both in terms of the number of tournament games and the offer aimed at participants or exhibitors. An important part of the event is the business zone, which allows to make new valuable business contacts through networking. Thanks to its development and support from various institutions, it has become an important part of gaming culture and a platform where the e-sports community and the technological community can meet, compete and inspire each other.",
    fbgroup: "Access to a closed investor group on FB",
    bgsTicket1:
      "Blu Game Show event ticket for one person valid until the end of 2024.",
    bgsTicket4:
      "Blu Game Show event ticket for four people valid until the end of 2024.",
    vipSet:
      "Blu Game Show VIP kit; T-shirt, lanyard, wristband, mouse pad, memory stick, cap.",
    investorStatue: "Investor statuette.",
    epulpitFree5:
      "Free access to the e-Desktop service for up to 5 positions for 30 days.",
    bgsVip2024: "Vip zone at the Blu Game Show event in 2024.",
    productPlacement2024: "Company placement at the 2024 Blu Game Show event.",
    ppvAccess: "Access to the PPV platform.",
    securityAudit: "Security audit.",
    trainings: "Opportunity to benefit from subsidised training through us.",
    recommendations: "Recommendations",
    awards: "Awards",
    csr: "CSR",
    strategicInvestor: "Strategic Investors",
    patron: "Brand Patrons",
    investor: "Investors",
    crowdfounder: "Engaged Investors",

    faq1title: "Can I buy shares for another person, e.g. as a gift?",
    faq2title: "Does the company issue shares in paper form?",
    faq3title: "When will additional rewards be sent?",
    faq4title: "Does the company have debt?",
    faq5title: "Does the company own real estate?",
    faq6title: "Can I invest more than once?",
    faq7title: "What data do I need to provide if I want to invest?",
    faq8title:
      "What if I want to receive the prizes to an address other than my home address?",
    faq9title: "Can I invest if I live outside Poland?",
    faq10title: "Do I have to pay tax after purchasing shares in this issue?",
    faq11title:
      "Can I sell my shares? Do I have to pay tax on the sold shares?",
    faq12title: "Why is the share issue price higher than its nominal value?",
    faq13title:
      "What happens if the broadcast success threshold is not reached?",
    faq14title: "Does taking up shares mean a meeting with a notary?",
    faq15title: "Are the company's shares listed on the stock exchange?",
    faq16title:
      "Office of the Polish Financial Supervision Authority on crowdfunding - crowdfunding.",
    faq17title: "How was the Company's pre-money valuation prepared?",
    faq1content1:
      "Building financial and investment awareness among your loved ones is a very prudent future step and a great idea. Unfortunately, legal regulations do not allow you to purchase shares for another person. According to applicable regulations, it is necessary to purchase shares in your own name, and then you can give it in the form of a written gift to your grandchildren, parents, spouse, children or other close person.",
    faq1content2: "",
    faq2content1:
      "According to applicable law, shares must be registered in the electronic register of shareholders. This register is kept in a way that ensures the security and integrity of the stored data. Information about shareholders is disclosed in the register only after a new share issue is entered in the register of entrepreneurs.",
    faq2content2:
      "If you request it, we can provide you with a paper share certificate. However, it is important to understand that such a document is only symbolic and you are not entitled to any rights arising from the actual shares that are registered in the electronic share register.",
    faq3content1: "", // TODO we need information about rewards
    faq3content2: "",
    faq4content1: "", // TODO needs data
    faq4content2: "",
    faq5content1: "", // TODO needs data
    faq5content2: "",
    faq6content1:
      "Naturally, investments are aggregated, leading to the allocation of shares based on the final total.",
    faq6content2: "",
    faq7content1:
      "You must provide your full name and surname, including Polish characters (according to the data on your ID card), as well as your date of birth. It is also necessary to provide your address, but it does not have to be your residential address.",
    faq7content2: "",
    faq8content1:
      "When purchasing shares, please indicate the address to which any prizes are to be sent.",
    faq8content2: "",
    faq9content1:
      "Of course! If you are an investor from outside the country, please select SEPA Single Euro Payments Area transfer payment in the tPay system. Then select your country and make the transfer according to the payment operator's instructions.",
    faq9content2: "",
    faq10content1: "No.",
    faq10content2: "",
    faq11content1:
      "Of course. Shares are an object that you can trade freely. In order to purchase or sell shares, you conclude an agreement and then inform the company about the necessary changes in the shareholders' register.",
    faq11content2:
      "If you make a profit from a share sale transaction - that is, if the sale price exceeds the purchase price - you will have to pay a tax of 19% on the difference.",
    faq12content1:
      "The nominal value of shares is based on the company's initial capital and does not necessarily reflect its current market value. The company's market valuation depends on various factors such as financial performance, assets and liabilities, growth prospects, market environment and competence of the management team.",
    faq12content2: "",
    faq13content1:
      "Investors will be refunded the funds they paid. Details regarding the refund process are specified in the regulations (you can find them at the link in the footer).",
    faq13content2: "",
    faq14content1: "No.",
    faq14content2: "",
    faq15content1:
      "The company is not listed on the stock exchange and its shares are not available on the public market. However, if the company decides to debut on NewConnect or the WSE in the future, the shares will be transferred to a brokerage account in connection with this process.", // TODO I DON'T KNOW if it's good - comment by the author of the text
    faq15content2: "",
    faq16content1:
      "BluHolding bases its share issue offer on the principles recommended by the Polish Financial Supervision Authority. We encourage you to read the available materials prepared by the Polish Financial Supervision Authority regarding crowdfunding, issuers and investors:", // TODO custom list element
    faq16content2:
      "With this in mind, we encourage you to explore these materials, which constitute a valuable source of knowledge related to the process of investing, company financing and legal and financial aspects of crowdfunding.",
    faq17content1:
      "Two independent valuations were carried out to determine the company's value. One of them was commissioned to a specialized external company, and the other was conducted internally by the President of the Management Board. Both methods took into account both historical data and financial forecasts to create a comprehensive picture of the company's value.", // TODO paraphrase from Kolejkowo, we will have 3 estimates - I will correct it when we finally decide
    faq17content2: "",
    forInvestors: "For investors",
    forEmitters: "For emitters",
    docPatent: "Patent protection for a trademark",
    youDontFound: "You don't found what you were looking for? Ask us!",
    bpsiShort:
      "A reputable company specialising in the provision of high quality certified training and courses in various professional areas.",
    bpsiLong:
      "The activities of the BLU PROFESSIONAL SKILLS INSTITUTE include training in information and communication technology (IT), cinematography skills for cameramen, journalistic competences and marketing issues. The founding of Blu Professional Skills Institute took place in response to the growing demand for qualified professionals in the aforementioned fields. The company was created by experienced experts and practitioner educators who realised the need to provide comprehensive education that meets the growing challenges of the labour market and business.",
    history_bpsi:
      "One of the key moments in the formation of BPSI was the realisation that the growth of the IT industry and modern technology went hand in hand with the need for professionals with unique skills. Knowledge of programming, data analysis, web design or the operation of streaming equipment was becoming increasingly valued, but there was a lack of concretised educational programmes that were tailored to the needs of these fields. Therefore, the founders of BPSI decided to create an institution that would focus on providing practical knowledge and skills rather than just theory. They were inspired by the fact that people in IT, marketing or streaming TV often need specialised competences that cannot always be learned in general training courses or universities.",
    history_bluoffice:
      "The genesis of BluOffice was also a response to the need to separate the home address from the registered office address, which is often required for business operations. Also, the opening of a new branch of a company posed challenges in terms of premises, and a virtual address seemed the ideal solution. BluOffice addresses the need to provide companies with flexible, cost-effective and prestigious business and head office solutions. The founders understood that there was a demand for a virtual office service that would allow companies to focus on their business while avoiding the high costs of traditional offices. As a result, BluOffice has become an innovative solution that has served companies in and around Bydgoszcz.",
    infoSoon: "Coming soon",
    name: "Name and surname",
    formName: "Name",
    formEmail: "E-mail address",
    formPhone: "Phone number",
    formMsg: "Message",
    formBtn: "Send",
    menuMobileOpen: "Open menu",
    menuMobileClose: "Close menu",
    blumilShort:
      "BluHolding is the organizing partner of the LOGIS-MIL Trade Fair. The 2024 edition was held June 13-14, 2024 in Bydgoszcz. Preparations for LOGIS-MIL 2025 are underway",
    blumilLong:
      "LOGIS-MIL fair is an excellent opportunity to present offers aimed at the uniformed services sector. The event was created primarily with exhibitors in mind. It serves as a proven and effective way for them to build relationships with decision-makers in the supply chain, experts, commanders, soldiers, and officers of uniformed units. We already invite you to participate in LOGIS-MIL 2025, which is scheduled for June 2025. BluHolding supports the event's organization in cooperation with the Association of Suppliers for Uniformed Services and is the creator of CyberMil - the fair's division dedicated to IT, cybersecurity, and new technologies.<br /><br /><u><a href='https://cybermil.bluholding.pl'>More about LOGIS-MIL</a></u>",
  },
};

export default EN;
